<template>
  <BodySimple :loading="firstLoading">
    <template #header>
      <h2 class="text-xl font-bold">
        <img
          src="@/assets/images/3387286_payment_purse_shopping_wallet_icon.png"
          class="h-20 ml-2 inline-block"
        />
        تسویه حساب
      </h2>
      <ul class="list-inside list-disc mt-3">
        <li>
          دقت کنید که فقط درامد خود را می‌توانید برداشت کنید و این شامل شارژ و جایزه
          نمی‌شود.
        </li>
        <li>25 درصد از درامد شما هنگام تسویه حساب کسر می‌شود</li>
      </ul>
    </template>
    <template #default>
      <div class="flex flex-wrap">
        <div class="w-full sm:w-6/12 md:w-6/12 xl:w-4/12 sm:pl-3">
          <div class="bg-blueGray-100 border border-blueGray-200 rounded-md p-5">
            <div>درامد فعلی شما :</div>
            <div class="text-xl text-left mt-4" dir="ltr">
              {{ $number.format($store.state?.user?.earn) }}
            </div>
            <hr class="border-blueGray-200 mt-4" />
            <div class="text-xl text-left mt-4" dir="ltr">
              {{ $number.format(amount) }}
            </div>
            <div class="text-xlmt-4">میلاد نظری</div>
          </div>
        </div>
        <div class="w-full sm:w-6/12 md:w-6/12 xl:w-5/12 mt-5 sm:pr-3 sm:mt-0">
          <ErrorBox :errors="errors" class="mb-5" />
          <form @submit.prevent="submit">
            <MnrNumber
              title="شماره کارت"
              placeholder="شماره کارت 16 رقمی"
              inputDir="ltr"
              v-model="card"
              :counter="false"
              :disabled="disabaledForm"
              :status="errors?.cardNumber ? 'error' : ''"
              :msg="errors?.cardNumber?.[0]"
            />
            <MnrText
              title="نام و نام خانوادگی"
              placeholder="نام و نام خانوادگی صاحب کارت"
              hint="نام و نام خانوادگی باید منطبق با کارت باشد"
              class="mt-5"
              v-model="fullName"
              :disabled="disabaledForm"
              :status="errors?.fullName ? 'error' : ''"
              :msg="errors?.fullName?.[0]"
            />
            <MnrNumber
              title="مبلغ تسویه حساب"
              placeholder="مبلغ"
              before="تومان"
              inputDir="ltr"
              :counter="false"
              class="mt-5"
              :max="$store.state?.user?.earn"
              v-model="amount"
              :disabled="disabaledForm"
              :status="errors?.amount ? 'error' : ''"
              :msg="errors?.amount?.[0]"
            />
            <Button class="mt-5" color="cyan" type="submit" :disabled="disabaledForm">
              تسویه حساب
            </Button>
          </form>
        </div>
        <div class="mt-10 w-full">
          <h2 class="text-xl font-bold mb-10">آخرین تسویه حسابها</h2>
          <div class="mb-2" v-for="old in olds" :key="old.id">
            <div
              class="flex flex-wrap w-full p-2 odd:bg-blueGray-100 even:bg-blueGray-50"
            >
              <div class="w-6/12 md:w-4/12 xl:w-2/12 text-center p-2">
                {{ old.card_number }}
              </div>
              <div class="w-6/12 md:w-4/12 xl:w-2/12 text-center p-2">{{ old.name }}</div>
              <div class="w-6/12 md:w-4/12 xl:w-2/12 text-center p-2">
                {{ old.created_at.fa }}
              </div>
              <div class="w-6/12 md:w-4/12 xl:w-2/12 text-center p-2">
                {{ $number.format(old.amount) }}
              </div>
              <div class="w-6/12 md:w-4/12 xl:w-2/12 text-center p-2">
                <span class="font-bold text-green-500" v-if="old.pay == 'ok'">
                  واریز شده
                </span>
                <span class="font-bold text-amber-500" v-else-if="old.pay == 'no'">
                  در انتظار
                </span>
                <span class="font-bold text-red-500" v-else>خطا</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
import MnrText from "@/components/mnr/MnrText.vue";
import MnrNumber from "@/components/mnr/MnrNumber.vue";
import Button from "@/components/Button.vue";
import ErrorBox from "@/components/ErrorBox.vue";

export default {
  components: {
    MnrText,
    MnrNumber,
    Button,
    BodySimple,
    ErrorBox,
  },
  data() {
    return {
      olds: [],
      firstLoading: true,
      card: null,
      fullName: "",
      amount: this.$store.state?.user?.earn,
      errors: {},
      disabaledForm: false,
    };
  },
  mounted() {
    let $this = this;
    $this.$axios
      .get("/api/checkouts", {
        params: { id: $this.$store.state.user.id },
      })
      .then(function (response) {
        $this.olds = response.data;
        // console.log(response);
      })
      .catch(function () {})
      .finally(function () {
        $this.firstLoading = false;
      });
  },
  methods: {
    submit() {
      // console.log("aaa");
      let $this = this;
      $this.errors = {};
      if ($this.card.toString().length != 16) {
        $this.errors.cardNumber = ["شماره کارت باید 16 رقم باشد"];
      }
      if (!$this.fullName) {
        $this.errors.fullName = ["نام و نام خانوادگی را وارد کنید"];
      }
      if ($this.amount < 50000) {
        $this.errors.amount = ["مبلغ تسویه حساب نباید کمتر از 50 هزار تومان باشد"];
      }

      if (Object.keys($this.errors).length) return;

      $this.disabaledForm = true;

      $this.$axios
        .post("/api/checkouts", {
          id: $this.$store.state.user.id,
          amount: $this.amount,
          cardNumber: $this.card,
          fullName: $this.fullName,
        })
        .then(function (response) {
          $this.olds.unshift(response.data.checkout);
        })
        .catch(function (error) {
          $this.errors = error.data;
        })
        .finally(function () {
          $this.disabaledForm = false;
        });
    },
  },
};
</script>
